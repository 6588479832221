// ////////////////////////////スマホ/タブレット判別
var _ua = (function (u) {
  return {
    Tablet: (u.indexOf('windows') != -1 && u.indexOf('touch') != -1) ||
      u.indexOf('ipad') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') == -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1) ||
      u.indexOf('kindle') != -1 ||
      u.indexOf('silk') != -1 ||
      u.indexOf('playbook') != -1,
    Mobile: (u.indexOf('windows') != -1 && u.indexOf('phone') != -1) ||
      u.indexOf('iphone') != -1 ||
      u.indexOf('ipod') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') != -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1) ||
      u.indexOf('blackberry') != -1
  };
})(window.navigator.userAgent.toLowerCase());
// if(_ua.Mobile){}

var osVer;
osVer = 'Android';

var ua = navigator.userAgent.toLowerCase();
var iPadFlag = false;
if (/android|ipod|ipad|iphone|macintosh/.test(ua) && 'ontouchend' in document) {
  iPadFlag = true;
}
// if (navigator.userAgent.indexOf(osVer)>0){
// }

var breakNum = 768;
var tabletNum = 1024;
// ////////////////////////////////////init
$(function () {
  // setScroll()
  if ($('#wrapper').hasClass('home')) {
  }
  // ///////////
  if (!_ua.Mobile && !_ua.Tablet) {
    $('#wrapper').addClass('desktop');
  // if (!$('#wrapper').hasClass('about')) {
  //   $('html, body').prop({
  //     scrollTop: 0
  //   })
  //   if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('edge') === -1) {
  //     $('#wrapper').addClass('safari')
  //   }else {
  //     setLuxy()
  //   }
  // }
  }
// ///////////
});

$(window).on('load', function () {
  // heightLineGroup()
  judgeWinSize();
  // setAcc()
  setHeader();
  setMainMenu();
  setGsap();
  // setCarousel01()
  // setCarousel02()
  // setCarousel03('.carouselStyle_03')
  // setTimeout(function () {
  //   setCarousel03('.carouselStyle_04')
  // // setCartBtn()
  // }, 1000)
  // // setSpan()
  // setMainMenu()
  // setWay()
  setLoaded();
  setLargeBnr();
  setSearch();
  setFixedBnr();
  // setHeadLine()
  // setGsap()
  // testFnc()
  // setHeader()
  if ($('#wrapper').hasClass('home')) {
    setTagScroll();
    setTagSearch();
    timer = setTimeout(function () {
      traceSlideBnr();
    }, 500);
    timer = setTimeout(function () {
      setCarousel02();
    }, 800);
  }
  if ($('#wrapper').hasClass('product-belmo')) {
    setCarousel04();
  }
  setCarousel01();
});

// function testFnc () {
//   $('.col-cart a').on('click', function () {
//     $('.shopify-buy__cart-toggle').click()
//   })
// }

// 固定バナー
function setFixedBnr () {
  // $('.visBnr').clone().appendTo('.fixedBnrWrap')

  var pos = 0;

  $(window).bind('scroll', function () {
    if ($(this).scrollTop() < pos) {
      $('#wrapper').removeClass('down');
      $('#wrapper').addClass('up');
    }else {
      $('#wrapper').removeClass('up');
      $('#wrapper').addClass('down');
    }
    pos = $(this).scrollTop();

    scrollHeight = $(document).height();
    scrollPosition = $(window).height() + $(window).scrollTop();
    if ((scrollHeight - scrollPosition) / scrollHeight <= 0.05) {
      $('#wrapper').removeClass('up');
      $('#wrapper').addClass('down');
    }
  });

  // pc
  var winW = $(window).width();
  var timer = false;
  var currentWidth = window.innerWidth;
  var baseHeight = $('.largeBnr').offset().top - $('.largeBnr').height();
  if ($('#wrapper').hasClass('home')) {
    var visHeight = $('#mainVis').offset().top;
  }else {
    var visHeight = 0;
  }

  window.addEventListener('resize', function () {
    winW = $(window).width();
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      baseHeight = $('.largeBnr').offset().top - $('.largeBnr').height();
      if ($('#wrapper').hasClass('home')) {
        visHeight = $('#mainVis').offset().top;
      }else {
        visHeight = 0;
      }
    }, 200);
  });
  $(window).scroll(function () {
    if ($(this).scrollTop() <= baseHeight) {
      $('#wrapper').removeClass('hideBnr');
    }else if ($(this).scrollTop() > baseHeight) {
      $('#wrapper').addClass('hideBnr');
    }
    if ($(this).scrollTop() <= visHeight) {
      $('#wrapper').removeClass('bnrShadow');
    }else if ($(this).scrollTop() > visHeight) {
      $('#wrapper').addClass('bnrShadow');
    }
  });
}

// タグ検索
function setTagSearch () {
  $('.tagLoop .labelTx').on('click', function () {
    const searchTx = $(this).text();
    const target = $('input[data-id="makeshop-search-keyword"]');
    const searchBtn = target.parents('.searchMod').find('.search-url');
    target.val(searchTx);
    searchBtn[0].click();
  });
}

// //////tagScroll
function setTagScroll () {
  var baseW = $('.tagLoop').width();
  var innerW = $('.tagLoop ul').width() * 2;

  if (baseW <= innerW) {
    var dupliHtml = $('.tagLoop').html();
    $('.tagLoop').append(dupliHtml);
    $('.tagLoop').addClass('move');
  }
}

// SPECIAL BNR
function traceSlideBnr () {
  const total = $('.bx-wrapper li:not(.bx-clone) a').length;
  $('.bx-wrapper li:not(.bx-clone) a').each(function (index) {
    const item = $(this).clone();
    const thumb = $(this).find('img').clone();
    $('.carouselStyle_02 .screen').append(item);
    $('.carouselStyle_02 .thumbnail').append(thumb);
    if (index === total - 1) {
      $('.carouselStyle_02 .screen a').wrap('<div class="carouselItem"><div class="item"></div></div>');
      $('.carouselStyle_02 .thumbnail img').wrap('<div class="carouselItem"><div class="item"></div></div>');
    }
  });
}

// 検索関連
function setSearch () {
  $('.anyInput').focus(function () {
    $('.anyInput').removeAttr('data-id');
    $(this).attr('data-id', 'makeshop-search-keyword');
    if ($(this).hasClass('anyInput_01')) {
      // $('.anyInput_01').val('')
      $('.anyInput_02').val('');
      $('.anyInput_03').val('');
    }else if ($(this).hasClass('anyInput_02')) {
      $('.anyInput_01').val('');
      // $('.anyInput_02').val('')
      $('.anyInput_03').val('');
    }else {
      $('.anyInput_01').val('');
      $('.anyInput_02').val('');
    // $('.anyInput_03').val('')
    }
  });
// console.log('oooo')
// $('.anyInput').focus(function () {
//   if ($(this).hasClass('anyInput_01')) {
//     $('.anyInput_02').val('')
//   }else {
//     $('.anyInput_01').val('')
//   }
// })
// $('header input, #menuPanel input').focus(function () {
//   var btn = $(this).parents('.searchWord').find('.btnSubmit')
//   $('.searchWord .btnSubmit').removeClass('search-url')
//   btn.addClass('search-url')
// })
}

// ラージバナー
function setLargeBnr () {
  $('.largeBnr a.mainLink').hover(function () {
    $(this).parents('.largeBnr').addClass('ov');
  }, function () {
    $(this).parents('.largeBnr').removeClass('ov');
  });
}

// カルーセル
function setCarousel01 () {
  var target = '.carouselStyle_01,.carouselStyle_03';
  $(target).each(function () {
    var slider = $(this).find('.screen');
    var slideCount = $(this).find('.screen>.carouselItem').length;
    var baseCount = slideCount + 1;
    if (baseCount > 2) {
      slider.slick({
        // initialSlide: randomStart,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 8000,
        arrows: true,
        // fade: true,
        speed: 500,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
      // cssEase: 'cubic-bezier(.645, .045, .355, 1)'
      });
    }
    slider.on('touchmove', function (event, slick, currentSlide, nextSlide) {
      slider.slick('slickPlay');
    });

    var timer = false;
    var currentWidth = window.innerWidth;
    window.addEventListener('resize', function () {
      if (currentWidth == window.innerWidth) {
        return;
      }
      currentWidth = window.innerWidth;
      if (timer !== false) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        slider.slick('slickPlay');
      }, 200);
    });
  });
}

function setCarousel02 () {
  var target = '.carouselStyle_02';
  $(target).each(function () {
    var slider = $(this).find('.screen');
    var slideCount = $(this).find('.screen>.carouselItem').length;
    var baseCount = slideCount + 1;

    if (baseCount > 2) {
      slider.slick({
        // initialSlide: randomStart,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 8000,
        arrows: true,
        // fade: true,
        speed: 500,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
        asNavFor: '.carouselStyle_02 .thumbnail',
      // cssEase: 'cubic-bezier(.645, .045, .355, 1)'
      });
    }
    slider.on('touchmove', function (event, slick, currentSlide, nextSlide) {
      slider.slick('slickPlay');
    });

    var thumb = $(this).find('.thumbnail');
    var thumbCount = $(this).find('.thumbnail>.carouselItem').length;
    var baseCount = slideCount + 1;
    thumb.slick({
      // initialSlide: randomStart,
      slidesToShow: 3,
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 8000,
      arrows: false,
      // fade: true,
      speed: 500,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      asNavFor: '.carouselStyle_02 .screen',
      focusOnSelect: true,
    // cssEase: 'cubic-bezier(.645, .045, .355, 1)'
    });
    thumb.on('touchmove', function (event, slick, currentSlide, nextSlide) {
      thumb.slick('slickPlay');
    });

    var timer = false;
    var currentWidth = window.innerWidth;
    window.addEventListener('resize', function () {
      if (currentWidth == window.innerWidth) {
        return;
      }
      currentWidth = window.innerWidth;
      if (timer !== false) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        thumb.slick('setPosition');
      // slider.slick('slickPlay')
      }, 200);
    });
  });
}

function setCarousel04 () {
  var target = '.carouselStyle_04';
  $(target).each(function () {
    var slider = $(this).find('.screen');
    var slideCount = $(this).find('.screen>.carouselItem').length;
    var baseCount = slideCount + 1;

    if (baseCount > 2) {
      slider.slick({
        // initialSlide: randomStart,
        dots: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 8000,
        arrows: true,
        // fade: true,
        speed: 500,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
        asNavFor: '.carouselStyle_04 .thumbnail',
      // cssEase: 'cubic-bezier(.645, .045, .355, 1)'
      });
    }
    slider.on('touchmove', function (event, slick, currentSlide, nextSlide) {
      slider.slick('slickPlay');
    });

    var thumb = $(this).find('.thumbnail');
    var thumbCount = $(this).find('.thumbnail>.carouselItem').length;
    var baseCount = slideCount + 1;
    thumb.slick({
      // initialSlide: randomStart,
      slidesToShow: 3,
      dots: false,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 8000,
      arrows: false,
      // fade: true,
      speed: 500,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      asNavFor: '.carouselStyle_04 .screen',
      focusOnSelect: true,
    // cssEase: 'cubic-bezier(.645, .045, .355, 1)'
    });
    thumb.on('touchmove', function (event, slick, currentSlide, nextSlide) {
      thumb.slick('slickPlay');
    });

    var timer = false;
    var currentWidth = window.innerWidth;
    window.addEventListener('resize', function () {
      if (currentWidth == window.innerWidth) {
        return;
      }
      currentWidth = window.innerWidth;
      if (timer !== false) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        thumb.slick('setPosition');
      // slider.slick('slickPlay')
      }, 200);
    });
  });
}
// //////////////////////////メインメニュー
function setMainMenu () {
  var current_scrollY;
  $('#menuBtn').on('click', function () {
    if (!$('#menuBtn').hasClass('active')) {
      openFnc();
    }else {
      closeFnc();
    }
  });
  // $('#mainNav a').on('click', function (event) {
  //   event.stopPropagation()
  // })
  //
  $('#clickBlocker').on('click', function () {
    closeFnc();
  });

  var winW = $(window).width();
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    winW = $(window).width();
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearInterval(timer);
    }
    timer = setTimeout(function () {
      if (winW > tabletNum) {
        closeFnc();
      }
    }, 200);
  });
}
function openFnc () {
  current_scrollY = $(window).scrollTop();
  $('#menuBtn').addClass('active');
  $('#wrapper').addClass('menuOpen');
  $('#outerMenu').css('top', -(current_scrollY));
  // 検索補助
  // $('header .btnSubmit').removeClass('search-url')
  // $('#menuPanel .btnSubmit').addClass('search-url')
  setTimeout(function () {
    $('html, body').prop({
      scrollTop: 0
    });
  }, 100);
}

function closeFnc () {
  $('#menuBtn').removeClass('active');
  $('#outerMenu').css('top', '');
  $('#wrapper').removeClass('menuOpen');
  // 検索補助
  // $('header .btnSubmit').addClass('search-url')
  // $('#menuPanel .btnSubmit').removeClass('search-url')
  $('html, body').prop({
    scrollTop: current_scrollY
  });
}

// ///////ヘッダー制御
function setHeader () {
  fixedHeader();

  $(window).scroll(function () {
    fixedHeader();
  });

  var winW = $(window).width();
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    winW = $(window).width();
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      $('#wrapper').removeClass('scrollHeader');
    }, 200);
  });

  function fixedHeader () {
    var h = 10;
    baseHeight = h;
    if ($(this).scrollTop() <= baseHeight) {
      $('#wrapper').removeClass('scrollHeader');
    }else if ($(this).scrollTop() > baseHeight) {
      $('#wrapper').addClass('scrollHeader');
    }
  }
}

// gsap
function setGsap () {
  gsap.registerPlugin(ScrollTrigger);

  // スクロールでクラス付与
  document.querySelectorAll('.way,.js-way,.secStyle_01,.secStyle_02,.secStyle_03,.secStyle_04').forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      id: index + 1,
      start: 'top 50%',
      toggleClass: {targets: el, className: 'is-active'},
      once: true,
    // markers: true
    });
  });

  // スクロールでクラス付与
  document.querySelectorAll('.articleStyle_01').forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      id: index + 1,
      start: 'top 70%',
      toggleClass: {targets: el, className: 'is-active'},
      once: true,
    // markers: true
    });
  });
}

// ///////waypoint
// function setWay () {
//   $('.way,.alphaWay,.js-way,.luxy-el').waypoint(
//     function (direction) {
//       var activePoint = $(this.element)
//       if (direction === 'down') {
//         // scroll down
//         activePoint.addClass('is-active')
//         timer = setTimeout(function () {
//           activePoint.addClass('activeAfter')
//         }, 1000)
//       }
//     },
//     {
//       offset: '70%'
//     }
//   )
// }

// ////////////////////////////アコーディオン
function setAcc () {
  $('.accInfo .ttl').on('click', function () {
    $(this).toggleClass('active');
    $(this).next().stop().slideToggle('fast');
  });
}

// ////////////ウィンドウサイズを判別
function judgeWinSize () {
  var winW = $(window).width();
  if (winW > breakNum) {
    $('#wrapper').addClass('setPc');
  } else {
    $('#wrapper').addClass('setSp');
  }

  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      winW = $(window).width();
      if (winW > breakNum) {
        $('#wrapper').addClass('setPc');
        $('#wrapper').removeClass('setSp');
      } else {
        $('#wrapper').addClass('setSp');
        $('#wrapper').removeClass('setPc');
      }
    }, 200);
  });
}

// //////////////////////////////ロード完了
function setLoaded () {
  $('#loading').addClass('loaded');
  $('#wrapper,.shopify-buy-frame').addClass('loaded');
  if ($('#wrapper').hasClass('home')) {
    loadStart();
  }else {
    $('#wrapper,.shopify-buy-frame').addClass('loadedDone');
    $('#wrapper,.shopify-buy-frame').addClass('loadEnd');
  }
}

function loadStart () {
  timer = setTimeout(function () {
    $('#wrapper,.shopify-buy-frame').addClass('loadedDone');
  }, 400);
  timer = setTimeout(function () {
    $('#wrapper,.shopify-buy-frame').addClass('loadEnd');
  }, 1500);
// $('body,html').animate({
//   scrollTop: 0
// }, 0, 'swing')
}

// function setGnav () {
//   var i = 0
//   setInterval(function () {
//     $('#headNav li').eq(i).addClass('start')
//     i++
//     if (i >= $('#headNav li').length) i = 0
//   }, 100)
// }

// ////////////////////////////////////ランドスケープ判定
var isLandscape = function () {
  if (window.innerHeight > window.innerWidth) {
    jQuery('body').addClass('portrait');
    jQuery('body').removeClass('landscape');
  } else {
    jQuery('body').addClass('landscape');
    jQuery('body').removeClass('portrait');
  }
};

if (_ua.Mobile) {
  jQuery(window).resize(function () {
    isLandscape();
  });
  isLandscape();
}

// //////////////////////////////高さ揃え
function heightLineGroup () {
  // setAutoHeight('#photoDiary .articleStyle_02 .inner')
  var winW = $(window).width();
  if (winW > breakNum) {
    // setAutoHeight('.setH_01 h2', 4)
    // setAutoHeight('.setH_01 h2')
    // setAutoHeight('#photoDiary .articleStyle_02 .inner')
    // setAutoHeight(".setH_01 .summary",3)
  } else {
    // setAutoHeight('.setH_01 h2', 2)
    // setAutoHeight('.setH_02 h2', 2)
    // setAutoHeight("#setH_08 h2",2)
  }

  startResize();
}

function setAutoHeight (target, count) {
  $(target).tile(count);
}

function startResize () {
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      heightLineGroup();
    }, 200);
  });
}
