breakPoint = 959;


/* js-visual__anime
--------------------------------------------------*/
function pageVisual(){

var pageindex = new TimelineMax();
pageindex.call(function() {
$('.js-visual__anime').addClass("active");
}, 
null, null, 0);

}

/* slide
--------------------------------------------------*/
(function ($, window, document, undefined) {
  $(function () {
    /* windowサイズの取得
    --------------------------------------------------*/
    var window_width; //window幅
    var window_height; //window高さ
    var is_sp;  //スマホ版

    $(window).on('resize.front-page', function(){
      window_width = $(window).width();
      window_height = $(window).height();
      if(window_width > 959) {
        is_sp = false;
      }else {
        is_sp = true;
      }
    }).resize();

    /* スライダー
    --------------------------------------------------*/
	var $keyvisual = $('.l-main__slide');
	$(function () {
		 keyvisualSlide();
	});
	
    var $slider = $('.slider-unit', $keyvisual);
    var $slide = $('.slide-item', $slider);
    var slide_max_num = $slide.length; //スライド画像の枚数
    var slide_current_index = 0;//現在のスライドナンバー
    var slide_wait_time = 6200;//スライド切り替えの待機時間
    var nav_click = false;//ナビ連打禁止用フラグ
    var slider_st;
		
		//初回のみフル表示
			setTimeout(() => {
			   $('.slide-item').removeAttr('style');
			}, 7000);
	
	
    function keyvisualSlide(index){
      if(slider_st) clearTimeout(slider_st);
      nav_click = false;
			
			
      if(index != undefined) slide_current_index = index;
      var $current_slide = $slide.eq(slide_current_index);
      var slide_next_index = slide_current_index + 1;
      if(slide_next_index > slide_max_num - 1) slide_next_index = 0;

      $('.slider-navi li', $keyvisual).removeClass('is-current');
      var $current_navi = $('.slider-navi li', $keyvisual).eq(slide_current_index);
      $current_navi.addClass('is-current');
      $('.circle', $current_navi).css({
        'stroke-dashoffset' : '82'
      }).stop().animate({
        'stroke-dashoffset' : '164'
      }, slide_wait_time, 'linear');
      $('.slider-navi li .circle').not($('.circle', $current_navi)).stop().animate({
        'stroke-dashoffset' : '246'
      }, 500, 'linear');

      $slide.not($current_slide).css({'z-index': 0});
      $current_slide.css({'z-index': 1});
      $current_slide.addClass('is-active');
			
      $current_slide.on('transitionend webkitTransitionEnd', function(e){
        $slide.not($current_slide).removeClass('is-active');
        $(this).off('transitionend webkitTransitionEnd');
        $('span', $current_slide).off('transitionend webkitTransitionEnd');
        nav_click = true;
      });

      $('span', $current_slide).on('transitionend webkitTransitionEnd', function(e){
        e.stopPropagation();
      });

      slide_current_index = slide_next_index;

      slider_st = setTimeout(function(){
        requestAnimationFrame(function(){
          keyvisualSlide();
        });
      }, slide_wait_time);
    }
	
    $keyvisual.append('<ul class="slider-navi f-en"></ul>');
    for(var i = 0; i < slide_max_num; i++) {
      $('.slider-navi', $keyvisual).append('<li><svg viewBox="0 0 26 26"><path class="circle" d="M13,1A12,12,0,1,0,25,13,12,12,0,0,0,13,1Z"/></svg></li>');
    }
    $('.slider-navi li', $keyvisual).on('click', function(e){
      var target_index = $(this).index();
      if(!$(this).hasClass('is-current') && nav_click){
        keyvisualSlide(target_index);
      }
    });
		
	
  });
})(jQuery, window, window.document);



/* function
--------------------------------------------------*/
$(function(){
	pageVisual();
});